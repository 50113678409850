import React from 'react';
import NextLink from 'next/link';
import {
	ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalHeader,
	Button, Text,
	Link as ChakraLink,
	Input, FormControl, Icon, Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import textTW from 'utils/common.json'; // due to i18n remove temporarily
import axios from 'utils/axios';

export function test() {
	return (
		<div> </div>
	);
}

export function ModalShowLoginSuccess({
	onClose, router, message,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalBody my={10} textAlign="center">
					<Text fontSize="lg">
						{message}
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="80px"
						onClick={() => {
							onClose();
							router.replace('/login');
						}}
					>
						確定
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

export function ModalShowLoginFail({
	onClose, router, message,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalBody my={10} textAlign="center">
					<Text fontSize="lg">
						{message}
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="80px"
						onClick={() => {
							onClose();
							router.replace('/');
						}}
					>
						確定
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

export function ModalShowAIStatement({
	onClose, start,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader mt={10}>
					<Text fontSize="2xl" textAlign="center">
						{textTW.thinkAI}
						說明
					</Text>
				</ModalHeader>
				<ModalBody my={4} px={10}>
					{/* {message?.split('/n').map((m) => (
						<Text my={4} fontSize="md">{m}</Text>
					))} */}
					<Text my={4} fontSize="md">
						此房間將會開啟
						{textTW.thinkAI}
						服務，所有想法皆有可能被傳送至
						{textTW.thinkAI}
						運算，請詳閱
						{textTW.thinkAI}
						使用條款，一旦開啟即表示您同意及接受
						<NextLink passHref href="/ai-terms">
							<ChakraLink
								borderBottom="1px solid #333"
								_hover={{ borderBottom: '1px solid #333' }}
								isExternal
							>
								{textTW.thinkAI}
								使用條款
							</ChakraLink>
						</NextLink>
						。
					</Text>
					<Text my={4} fontSize="md">
						{textTW.thinkAI}
						的產出僅作為參考的依據，無法保證皆完全正確無誤，且本網站不承擔使用
						{textTW.thinkAI}
						服務所造成之損失。
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						variant="outline"
						borderRadius="40px"
						colorScheme="orange"
						borderColor="pryOrange"
						color="pryOrange"
						size="md"
						mx={4}
						width="120px"
						onClick={onClose}
					>
						返回
					</Button>
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="120px"
						onClick={() => {
							onClose();
							start();
						}}
					>
						下一步
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

export function ModalAdjustPlus({
	onClose, mutate, user,
}) {
	const { register, handleSubmit } = useForm();
	const editProfile = async (data) => {
		await axios.patch(`/api/v1/user/membership/${user?._id}`, {
			membership: 'plus',
			expire: dayjs(`${data?.expireDate} 23:59:59`).toISOString(),
		});
		mutate();
		onClose();
		alert(`已將 ${user?.name} 設成星可+ Plus。請注意，使用者需登出並重新登入才能生效。`);
	};
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={handleSubmit(editProfile)}>
					<ModalBody my={10} textAlign="center">
						<Text fontSize="lg">
							請設定到期時間
						</Text>
						<FormControl isRequired>
							<Input
								placeholder="請設定到期時間"
								size="md"
								type="date"
								{...register('expireDate')}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter mb={4} display="flex" justifyContent="center">
						<Button
							type="submit"
							borderRadius="40px"
							colorScheme="orange"
							bgColor="pryOrange"
							size="md"
							width="80px"
						>
							確定
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</>
	);
}

export function ModalShowRoomFull({
	onClose, router, reason,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader mt={10}>
					<Flex justifyContent="center">
						<Icon as={Fa} color="pryOrange" icon={faTrash} ml={2} size="2x" />
					</Flex>
				</ModalHeader>
				<ModalBody my={4} px={10}>
					{reason?.includes('max events exceeded') ? (
						<Text my={4} fontSize="xl" textAlign="center">
							房間紀錄已達上限
							<br />
							請先刪除再新增房間
						</Text>
					) : (
						<Text my={4} fontSize="xl" textAlign="center">
							無法建立房間
							<br />
							請稍後再試
						</Text>
					)}
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						onClick={() => {
							onClose();
							router.replace('/room');
						}}
					>
						升級容納更多房間
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}
